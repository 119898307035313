import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import ModalDialog from 'src/components/ModalDialog/index';
import TOSAndPrivacyStatementText from 'src/containers/TOSAndPrivacyStatement/TOSAndPrivacyStatementText';
import TOSAndPrivacyUpdateStatementText from 'src/containers/TOSAndPrivacyStatement/TOSAndPrivacyUpdateStatementText';
import TOSUpdateStatementText from 'src/containers/TOSAndPrivacyStatement/TOSUpdateStatementText';
import PSUpdateStatementText from 'src/containers/TOSAndPrivacyStatement/PSUpdateStatementText';
import { agreeToTermsOfService } from 'src/actions/accountActions';
import helpers from 'src/helpers';

import tosAndPrivacyStatementTextStyle from 'src/containers/TOSAndPrivacyStatement/tosAndPrivacyStatementText.style';

function TOSAndPrivacyStatement({ classes, logout }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const {
    agreedToPS,
    agreedToTOS,
    agreedToTOSDateTime,
    agreedToPSDateTime,
  } = user || {};

  const isAdmin = helpers.isUserAdmin(user);

  const handleCloseModal = () => {
    logout();
  };

  const handleClickIAgree = () => {
    dispatch(agreeToTermsOfService());
  };

  if ((user && agreedToPS && agreedToTOS) || (isAdmin && agreedToPS === undefined && agreedToTOS === undefined)) {
    return null;
  }

  const titleToUse = !agreedToTOS && !agreedToPS ? 'Terms of Service and Privacy Policy' : !agreedToTOS ? 'Terms of Service' : 'Privacy Policy';

  const contentToUse = () => {
    if (!agreedToTOS && !agreedToPS) {
      if (agreedToTOSDateTime || agreedToPSDateTime) {
        return (
          <TOSAndPrivacyUpdateStatementText classes={classes} />
        );
      }
      return (
        <TOSAndPrivacyStatementText classes={classes} />
      );
    }
    if (!agreedToTOS) {
      return (
        <TOSUpdateStatementText classes={classes} />
      );
    }
    if (!agreedToPS) {
      return (
        <PSUpdateStatementText classes={classes} />
      );
    }
    return null;
  };

  return (
    <ModalDialog
      title={titleToUse}
      onClose={handleCloseModal}
      onOk={handleClickIAgree}
      content={contentToUse()}
      closeText="Log Out"
      okText="Agree"
      isVisible
      closeOnClickOnlyTheButton
    />
  );
}

TOSAndPrivacyStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default withStyles(TOSAndPrivacyStatement, tosAndPrivacyStatementTextStyle);
